import { Container, Row, Col, Image } from "react-bootstrap";
import MyButton from "./Button";
import img from "../assets/img/image-2.png";

export default function About() {

  const brandStyle = {
    color: 'white',
  };

  return (
    <Container className="py-5" >
      <Row>
        <Col lg={6} md={6} sm={12}>
          <Image src={img} fluid />
        </Col>
        <Col lg={6} md={6} sm={12} className="d-flex align-items-center" style={{marginTop:0}}>
          <div className="text-lg-end text-md-end text-center">
            <h1 className="font-primary color-primary">About Us</h1>
            <p className="font-secondary color-secondary py-3">
            Whether you're looking for a new opportunity or seeking skilled professionals to join your team, we're here to make the process seamless and efficient.
            </p>

            <p className="font-secondary color-secondary py-1">
               We are UK Leading IT Recruitment Agency. Your First Destination for Tech + Digital Talent.

            </p>

            <p className="font-secondary color-secondary py-3">

              Searching for your next career move? Or on the hunt for exceptional IT talent? If yes - then look no further as you've arrived at your destination.

              With over 25 years in the business, we are the most experienced IT recruitment agency there is. We work across all sectors and business sizes, and place people UK WIDE. And we do it with transparency, honesty, and a lot of hard work.

              But by no means does that suggest we're generalists. Rather, the depth and diverseness of our experience means you'll be looked after by specialists. Experts that really know their stuff. Who will help you navigate the intricacies of the market to find you that perfect job, or that indispensable employee. We supply contract, permanent, and temp staff.
            </p>

            <MyButton style={{ marginTop: '10px' }} text="Contact Us" />

          </div>
        </Col>
      </Row>
    </Container>
  );
}
