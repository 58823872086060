import React, { useState } from 'react';
import Marquee from "react-marquee-slider";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import indImg1 from "../assets/img/ind-1.jpg"; // Example images for charities
import indImg2 from "../assets/img/ind-2.png"; 
import indImg3 from "../assets/img/ind-3.jpg"; 
import indImg4 from "../assets/img/ind-4.jpg"; 
import indImg5 from "../assets/img/ind-5.jpg"; 
import indImg6 from "../assets/img/ind-6.jpg"; 
import indImg7 from "../assets/img/ind-7.png"; 
import indImg8 from "../assets/img/ind-8.webp"; 
import indImg9 from "../assets/img/ind-9.jpg"; 
import indImg10 from "../assets/img/ind-10.jpg"; 
import indImg11 from "../assets/img/ind-11.jpg"; 
import indImg12 from "../assets/img/ind-12.png"; 

const SectionTitle = styled.h1`
  color: #4F4F4F;
  text-align: center;
  margin-bottom: 2rem;
`;

const IndustryCard = styled.div`
  width: 300px; /* Fixed width */
  height: 350px; /* Fixed height */
  padding: 20px;
  border: 3px solid #6debb5;
  margin-right: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer; /* Make cards clickable */
`;

const IndustryDescription = styled.p`
  font-size: 0.9rem;
  color: #6C757D;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const IndustryTitle = styled.h5`
  margin: 0;
  font-size: 1.1rem;
`;

export default function IndustriesCovered() {
  // State to manage the velocity of the marquee
  const [isPaused, setIsPaused] = useState(false);

  // Define the industries (job roles) you want to display
  const industryItems = [
    {
      id: 1,
      img: indImg1,
      name: "1st-3rd Line Support Engineers",
      description:
        "Find skilled support engineers ensuring smooth business operations.",
    },
    {
      id: 2,
      img: indImg2,
      name: "Network Engineers",
      description:
        "Connect with brilliant network engineers to design, implement and maintain network infrastructures.",
    },
    {
      id: 3,
      img: indImg3,
      name: "Cloud Engineers",
      description:
        "We’ll source top cloud engineers who will build and manage scalable cloud solutions for your business.",
    },
    {
      id: 4,
      img: indImg4,
      name: "Business Analysts",
      description:
        "Find business analysts to translate business needs into actionable insights and strategies.",
    },
    {
      id: 5,
      img: indImg5,
      name: "Test Engineers",
      description:
        "Hire test engineers to deploy high-performance, bug-free software.",
    },
    {
      id: 6,
      img: indImg6,
      name: "IT Sales",
      description:
        "We’ll bring you IT sales professionals who will drive growth and sales transformation.",
    },
    {
      id: 7,
      img: indImg7,
      name: "Project Management",
      description:
        "Hire experienced project managers to lead cross-functional teams in ensuring smooth project executions.",
    },
    {
      id: 8,
      img: indImg8,
      name: "Helpdesk/Support",
      description:
        "Get matched with experienced managers who excel at providing proactive support and ensuring high performances. ",
    },
    {
      id: 9,
      img: indImg9,
      name: "Web Design/Development",
      description:
        "Get talented web designers and developers to create stunning, responsive websites.",
    },
    {
      id: 10,
      img: indImg10,
      name: "Development (C#/Java/PHP)",
      description:
        "Find developers proficient in C#, Java, and PHP to build robust, scalable solutions.",
    },
    {
      id: 11,
      img: indImg11,
      name: "Cyber Security",
      description:
        "Recruit cyber security experts to protect your business from evolving threats.",
    },
    {
      id: 12,
      img: indImg12,
      name: "Data Engineers/Analysts",
      description:
        "Get connected to fresh and experienced data engineers/analysts to turn data into insights.",
    },
  ];

  // Event handlers to pause and resume marquee
  const handlePause = () => setIsPaused(true);
  const handleResume = () => setIsPaused(false);

  return (
    <Container className="py-5">
        <SectionTitle className="font-primary">Industries We Cover</SectionTitle>
        <div className="text-center">
            <p className="font-secondary color-secondary py-1">
              At RizzOnline, we specialize in connecting talented professionals with opportunities in a wide range of industries. Our expertise extends to the following industries:
            </p>
        </div>
        <div style={{ height: "350px", overflow: "hidden" }}>
          {/* Set the velocity to 0 when paused */}
          <Marquee velocity={10} scatterRandomly={false} direction="rtl">
          {industryItems.map((industry) => (
            <IndustryCard key={industry.id}>
              <img src={industry.img} alt={industry.name} 
              style={{ width: "100%",    /* Ensures the image fills the container width */
                height: "150px",  /* Set a fixed height for all images */
                objectFit: "cover", /* Ensures the image scales and crops to fit without distortion */
                borderRadius: "0px"  }} />
              <h6 className="font-secondary color-primary mt-1"><b>{industry.name}</b></h6>
              <IndustryDescription className="font-secondary color-secondary">
                {industry.description}
              </IndustryDescription>
            </IndustryCard>
          ))}
        </Marquee>
        </div>
    </Container>
  );
};
