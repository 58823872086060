import React from "react";
import Marquee from "react-marquee-slider";
import styled from "styled-components";
import charityImg1 from "../assets/img/charity-1.jpg"; // Example images for charities
import charityImg2 from "../assets/img/charity-2.jpg";
import charityImg3 from "../assets/img/charity-3.jpeg"; // Example images for charities
import charityImg4 from "../assets/img/charity-4.jpg";
import charityImg5 from "../assets/img/charity-5.jpeg"; // Example images for charities
import charityImg6 from "../assets/img/charity-6.webp";

const SectionTitle = styled.h1`
  color: #4F4F4F;
  text-align: center;
  margin-bottom: 2rem;
`;

const CharityCard = styled.div`
  width: 300px; /* Fixed width */
  height: 350px; /* Fixed height */
  padding: 20px;
  ${'' /* background-color: #ffffff; */}
  border: 3px solid #6debb5;
  ${'' /* border-radius: 10px; */}
  margin-right: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure consistent spacing between content */
  align-items: center;

  /* To ensure cards stay the same size */
  overflow: hidden;
  box-sizing: border-box;
`;

const CharityDescription = styled.p`
  font-size: 0.9rem;
  color: #6C757D;
  max-height: 83px; /* Limit height for long text */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export default function CharitiesSupported() {
  const charityItems = [
    {
      id: 1,
      img: charityImg1,
      name: "The Flicka Foundation",
      description:
        "In South Cornwall, UK, Flicka sanctuary site now provides a safe home for over 100 donkeys and horses.",
    },
    {
      id: 2,
      img: charityImg2,
      name: "Nacro Housing",
      description:
        "Offers a range of housing services across England for the homeless, prison leavers and women.",
    },
    {
      id: 3,
      img: charityImg3,
      name: "Guide Dogs",
      description:
        "Supports blind and partially sighted people to live the life they choose.",
    },
    {
      id: 4,
      img: charityImg4,
      name: "The Donkey Sanctuary",
      description:
        "Donkey welfare charity Standing up for donkeys and challenging neglect and abuse.",
    },
    {
      id: 5,
      img: charityImg5,
      name: "St Petrocs",
      description:
        "Ending homelessness in Cornwall because everyone has the right to a safe and secure home.",
    },
    {
      id: 6,
      img: charityImg6,
      name: "Roundabout",
      description:
        "South Yorkshire’s charity providing shelter and life skills to homeless 16- to 25-year-olds.",
    },
  ];

  return (
    <div className="py-5">
      <SectionTitle className="font-primary">Charities We Support</SectionTitle>
      <div className="text-center">
        <p className="font-secondary color-secondary py-1">
          We are a non-profit organization and all profits go to charities. Some of the charities we sponsor are:
        </p>
      </div>
      <div style={{ height: "350px", overflow: "hidden" }}> {/* Set fixed height for Marquee */}
        <Marquee velocity={10} scatterRandomly={false} direction="rtl">
          {charityItems.map((charity) => (
            <CharityCard key={charity.id}>
              <img src={charity.img} alt={charity.name} 
              style={{ width: "100%",    /* Ensures the image fills the container width */
                height: "150px",  /* Set a fixed height for all images */
                objectFit: "cover", /* Ensures the image scales and crops to fit without distortion */
                borderRadius: "0px"  }} />
              <h6 className="font-secondary color-primary mt-1">{charity.name}</h6>
              <CharityDescription className="font-secondary color-secondary">
                {charity.description}
              </CharityDescription>
            </CharityCard>
          ))}
        </Marquee>
      </div>
    </div>
  );
}
