import Menu from "./components/Menu";
import Hero from "./components/Hero";
import Collections from "./components/Collections";
import Discount from "./components/Discount";
import HappyCustomer from "./components/HappyCustomer";
import About from "./components/About";
import Footer from "./components/Footer";
import { BrowserRouter as Router, useHistory, Switch, Route, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Image} from "react-bootstrap";
import MyButton from "./Button";
import img from "./image-1.jpg";
// import { useHistory} from 'react-router-dom';
// import Footer from "./Footer";
import React, { useRef } from 'react';
import styled from "styled-components";
// import { Container } from "react-bootstrap";
// import Button from "./Button";
import cover from "./discount-cover.jpg";
import { Navbar, Nav, Button} from "react-bootstrap";
import imgg from "./image-2.png"
import IndustriesCovered from "./components/IndustriesCovered"; // Import the IndustriesCovered component
import CharitiesSupported from "./components/CharitiesSupported";  // Import the new CharitiesSupported component


const AppContainer = styled.div`
  max-height: 100vh; /* Set a maximum height for the content area */
  overflow-y: auto; /* Allow vertical scrolling if the content exceeds the container height */
`;

export default function App() {
  const pageStyle = {
    backgroundColor: '#E6F7F7',
  };

  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
    // return <Footer refProp={footerRef}></Footer>
    console.log('schrolled')
  };



  const brandStyle1 = {
    color: '#4F4F4F',
    // marginBottom:20
  };

  const brandStyle2 = {
    color: '#4F4F4F',
    marginBottom:20
  };

  // discount
  const Discount = styled.div`
    background-image: url(${cover});
    background-size: cover;
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @media (max-width: 414px) {
      justify-content: center;
      height: 160px;
    }
  `;

  const Box = styled.div`
    height: 180px;
    width: 100%;
    background: linear-gradient(
      270deg,
      rgba(109, 235, 181, 0.9) 0%,
      rgba(11, 12, 13, 0) 100%
    );
    position: absolute;

    @media (max-width: 414px) {
      margin-right: 0rem;
      background: rgba(109, 235, 181, 0.2);
      height: 160px;
    }
  `;
  const brandStyle3 = {
    // color: '#4F4F4F',
    marginTop:20
  };

  const DiscText = styled.div`
    z-index: 1;
    margin-right: 5rem;
    @media (max-width: 414px) {
      margin-right: 0rem;
    }
  `;


  // menu

  const navbarStyle = {
    backgroundColor: '#13D5D5',
  };

  const brandStyle = {
    color: 'white',
    marginTop: 5,
    backgroundColor: '#13D5D5',

  };

  return (
    <AppContainer className="App"> {/* Wrap your content with the AppContainer */}
    <div className="App">
      {/* <Router> */}
        <div style={pageStyle}>
          {/* <Menu /> */}
      <Navbar style={navbarStyle} expand="lg" className="pt-3">
        <div className="container-fluid mx-5">
          <Navbar.Brand href="#home" className="font-primary fs-32" style={brandStyle}>
            {/* <img
              src={'Logo'}
              alt="logo"
              width="28"
              height="28"
              className="d-inline-block align-top mr-2"
            />  */}
            RizzOnline
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
          {/* <Nav.Link className="color-primary font-secondary fs-16 ms-lg-5 ms-md-5 ms-0" href="#collections">Collections</Nav.Link>
          <Nav.Link className="color-primary font-secondary fs-16 ms-lg-5 ms-md-5 ms-0" href="#products">Products</Nav.Link> */}
          {/* <Button onClick={scrollToFooter} style={brandStyle3} text="About" color="white" />
          <Button onClick={scrollToFooter} style={brandStyle3} text="Contact Us" color="white" /> */}

          <Button onClick={scrollToFooter} className="font-secondary fs-16 ms-lg-5 ms-md-5 ms-0" href="#About" style={brandStyle}>About</Button>
          <Button onClick={scrollToFooter} className="font-secondary fs-16 ms-lg-5 ms-md-5 ms-0" href="#About" style={brandStyle}>Contact Us</Button>
          </Nav>
        </Navbar.Collapse>
        </div>
      </Navbar>

        <Container className="py-5">
        <Row>
          <Col lg={6} md={6} sm={12} className="d-flex align-items-center">
            <div className="text-lg-start text-md-start text-center">
              <h1 className="font-primary" style={brandStyle1}>
                 Finding Top Talents
              </h1>
              <h5 className="font-secondary color-secondary py-3" style={brandStyle2}>
              Connecting <b> job seekers to dream careers </b> and helping employers find top talents.{" "}
              </h5>
              <MyButton onClick={scrollToFooter} text="About RizzOnline" icon="bi bi-arrow-right" />
            </div>
          </Col>
          <Col lg={6} md={6} className="d-none d-lg-block">
            <Image src={img} fluid />
          </Col>
        </Row>
        {/* <Discount /> */}
        <Container className="py-c">
          <Discount>
            <Box />
            <DiscText>
              <p className="font-primary fs-20" style={{ color: '#dbfffe', marginBottom: '20px', }}>
                Looking for Talents,
                <br />
                Home or Remote?
                <br />
              </p>
              <MyButton onClick={scrollToFooter} 
              style={{ ...brandStyle3, padding: '8px 16px', fontSize: '14px' }} 
              text="Contact Us" 
              color="white" />
            </DiscText>
          </Discount>
        </Container>
          <HappyCustomer />
          {/* <About /> */}
          {/* Add the IndustriesCovered component */}
          <IndustriesCovered />
          <CharitiesSupported /> {/* Add the CharitiesSupported section here */}
          <About/>
          {/* <Container className="py-5" >
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Image src={imgg} fluid />
              </Col>
              <Col lg={6} md={6} sm={12} className="d-flex align-items-center" style={{marginTop:20}}>
                <div className="text-lg-end text-md-end text-center">
                  <h1 className="font-primary color-primary">About Us</h1>
                  <p className="font-secondary color-secondary py-3">
                  Whether you're looking for a new opportunity or seeking skilled professionals to join your team, we're here to make the process seamless and efficient.
                  </p>
                  <MyButton onClick={scrollToFooter}text="Contact Us" />
                </div>
              </Col>
            </Row>
          </Container> */}
          <Footer />
        {/* <div style={{ height: '100vh' }}></div> Add a spacer to make space for the footer */}
      <div ref={footerRef}>
        {/* <Footer /> */}
      </div>
    </Container>

          {/* <Hero /> */}
          {/* <Collections /> */}
          {/* <Discount />
          <HappyCustomer />
          <About />
          <Footer /> */}
        </div>

      
        {/* <Switch> */}
        {/* <Route exact path="/components/Footer" component={Footer} /> */}
          {/* <Footer /> */}
          {/* <Hero /> */}
        {/* </Switch> */}
      {/* </Router> */}

    </div>  
    </AppContainer>

  );
}
